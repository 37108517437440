<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold"> Data Perusahaan </span>
            <span style="cursor: pointer" @click="close"> X </span>
          </v-col>
        </v-row>
        <template v-if="dataModal !== null">
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4">
              Nama Badan Usaha
            </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.name || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4">
              Bentuk Badan Usaha
            </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.type || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Bidang Usaha </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.sector || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Deskripsi </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.desc || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Kode KBLI </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.kbli_code || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> NPWP </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.npwp || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4">
              Tanggal Berdiri
            </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.date || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Alamat </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.address || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Nomer Telepon </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.phone || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Email </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.email || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Website </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.website || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4">
              Jumlah Karyawan
            </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataModal.employee_count || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hr />
            </v-col>
          </v-row>
          <v-row v-for="(v, i) in dataModal.list_doc" :key="'doc-' + i">
            <v-col class="txt16-gray50" cols="6" sm="4">
              {{ v.name || "-" }}
            </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ v.file_name || "-" }}
              <v-icon
                style="font-size: 16px; cursor: pointer"
                @click="previewURL(v)"
              >
                mdi-eye
              </v-icon>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataModal: {
      type: Object,
      default: null,
    },
  },

  data: () => ({}),

  computed: {},

  watch: {},

  created() {},

  methods: {
    close() {
      this.$emit("close");
    },

    previewURL(p) {
      // console.log(p)
      const url = [];
      url.push(p.url);
      this.$viewerApi({ images: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
