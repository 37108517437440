<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-row class="px-2 py-3">
      <v-col class="txt20-black"> Pengaturan Akun > Data Perusahaan </v-col>
    </v-row>
    <v-card class="px-2 mx-2" elevation="0">
      <v-data-table
        :headers="headers"
        :items="dataTable"
        :items-per-page="100"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="px-3 py-6">
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="#36AC87"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
            <v-col class="text-right" cols="12" sm="7">
              <v-btn
                color="#2E976C"
                class="text-capitalize"
                style="color: white"
                @click="toPerusahaanTambah"
              >
                Tambah Perusahaan
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.city`]="{ item }">
          {{ item.city || "-" }}
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <div style="max-width: 200px; white-space: break-spaces">
            {{ item.desc }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <div
              class="action-style"
              @click="toDetail(item)"
            >
              <v-icon class="action-icon"> mdi-eye </v-icon>
            </div>
            <div class="mx-2 action-style" @click="toDelete(item)">
              <v-icon color="#e21927" size="18" style="cursor: pointer">
                mdi-delete
              </v-icon>
            </div>
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#2E976C"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic">
              {{ itemCount + " dari " + itemTotal + " ditampilkan" }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <modal-detail
      :show="dialog"
      :data-modal="dataDetail"
      @close="closeDialog"
    />
    <modal-konfirmasi :show="modalDelete" @submit="confirmDelete" />
  </v-container>
</template>

<script>
import axios from "axios";
import ModalDetail from "../keanggotaan/ModalDetail.vue";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalDetail,
    ModalKonfirmasi,
  },

  data: () => ({
    dialog: false,
    dataDetail: null,

    search: "",
    headers: [
      { text: "Perusahaan", align: "start", value: "name", sortable: false },
      { text: "Nama Pemilik", value: "name", sortable: false },
      { text: "Bidang", value: "sector", sortable: false },
      { text: "Deskripsi", value: "desc", sortable: false },
      { text: "Domisili", value: "city", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Website", value: "website", sortable: false },
      { text: "", value: "actions" },
    ],
    dataTable: [],
    page: 1,
    pageCount: 0,
    itemCount: 0,
    itemTotal: 0,

    
    selectedItem: null,
    modalDelete: false,
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize(this.page);
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    toPerusahaanTambah() {
      this.$router.push({ name: "PengaturanPerusahaanTambah" });
    },

    toDetail(p) {
      // console.log(p)
      this.getDetail(p.mc_hash);
      this.dialog = true;
    },

    onChangePage(e) {
      this.page = e;
      this.initialize(e, this.search);
    },

    filterData() {
      this.initialize(this.page, this.search);
    },

    filterClear() {
      this.initialize(this.page);
    },

    initialize(page, search) {
      // Check user menus
      // const userMenus = localStorage.getItem('userMenus').split(',')

      // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
      //   this.isAllowVerification = true
      // }

      const requestBody = {
        // type: type,
        page: page,
        search: search,
      };

      axios
        .post("/v1/admin/setting/company", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.dataTable = res.data.data.company.list;
            this.pageCount = res.data.data.company.pagination.last_page;
            this.itemCount = res.data.data.company.pagination.per_page;
            this.itemTotal = res.data.data.company.pagination.total;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getDetail(p) {
      const requestBody = {
        mc_hash: p,
      };

      axios
        .post("/v1/admin/setting/company/detail", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data)
            this.dataDetail = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    toDelete(p) {
      this.selectedItem = p;
      this.modalDelete = true;
    },

    confirmDelete(p) {
      if (p === 0) {
        this.modalDelete = false;
      } else {
        this.submitDelete();
      }
    },

    submitDelete() {
      console.log(this.selectedItem);
      const requestBody = {
        mc_hash: this.selectedItem.mc_hash,
      };

      axios
        .post("/v1/admin/company/delete", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.modalDelete = false;
            this.$toast.success(res.data.message);
            this.initialize(this.page);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt20-black {
  @extend .h-5;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
